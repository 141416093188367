import React from 'react';

import Content from '@components/Layout/Content/Content';
import PageSection from '@components/Layout/PageSection/PageSection';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Button from '@components/Molecule/Button/Button';
import Copy from '@components/Typography/Copy';
import H from '@components/Typography/H';

const TermsAndConditionsPage = () => {
	return (
		<Page>
			<SEO title="Terms and Conditions | UHV Design" />

			<PageSection>
				<Content>
					<H level={2} as="h1">
						Terms and Conditions
					</H>
				</Content>

				<Content constrict>
					<H level={3} as="h2">
						Terms and conditions of sale
					</H>

					<Copy>
						Use the link below to download our standard Terms &
						Conditions of Sale.
					</Copy>

					<Button
						to={'/documents/terms-and-conditions-2011-v1-6.pdf'}
						variant="download"
					>
						Download PDF
					</Button>
				</Content>

				<Content constrict>
					<H level={3} as="h2">
						Terms and conditions of purchase
					</H>

					<Copy>
						Use the link below to download our standard Terms &
						Conditions of Purchase.
					</Copy>

					<Button
						to={'/documents/uhvd-conditions-of-purchase-rev3.pdf'}
						variant="download"
					>
						Download PDF
					</Button>
				</Content>
			</PageSection>
		</Page>
	);
};

export default TermsAndConditionsPage;
